import React from "react"
import { Link } from "gatsby"

import NewsletterBox from "../components/newsletterBox"

import banner from "../images/Banner_Lateral.png"

const ContentSidebar = ({ data }) => {
  return (
    <div className="content-sidebar">
      <NewsletterBox />

      <div className="top-picks">
        <h2>MAIS POPULARES DO MÊS</h2>
        <ul>
          {data.monthPopular.posts.map(node => (
            <li key={node.slug}>
              <Link
                to={`/${node.slug}`}
                dangerouslySetInnerHTML={{ __html: node.name }}
              ></Link>
            </li>
          ))}
        </ul>
      </div>

      {/* <div className="banner-side">
        <a href="https://nizam.app/pt" target="_blank" rel="noreferrer">
          <img src={banner} alt="Banner Lateral - Iqara Islam" />
        </a>
      </div> */}

      {/* <div className="quran-quotes">
        <h2>PASSAGEM DO DIA</h2>
        <blockquote>
          “O castigo, para aqueles que lutam contra Deus e contra o Seu
          Mensageiro e semeiam a corrupção na terra, é que sejam mortos, ou
          crucificados, ou lhes seja decepada a mão e o pé opostos, ou banidos.
          Tal será, para eles, um aviltamento nesse mundo e, no outro, sofrerão
          um severo castigo” – Alcorão, Suratra 5,33.
        </blockquote>
      </div> */}
    </div>
  )
}

export default ContentSidebar
