import React from "react"

const ContentResize = () => {
  const increaseFontSize = () => {
    const p = document.querySelectorAll(
      ".content-read p, .content-read ul li strong, .content-read h2, .content-read a"
    )

    p.forEach(el => {
      const currentSize = window
        .getComputedStyle(el, null)
        .getPropertyValue("font-size")
      const fontSize = parseFloat(currentSize)
      el.style.fontSize = fontSize + 1 + "px"
    })
  }

  const decreaseFontSize = () => {
    const p = document.querySelectorAll(
      ".content-read p, .content-read ul li strong, .content-read h2, .content-read a"
    )

    p.forEach(el => {
      const currentSize = window
        .getComputedStyle(el, null)
        .getPropertyValue("font-size")
      const fontSize = parseFloat(currentSize)
      el.style.fontSize = fontSize - 1 + "px"
    })
  }

  return (
    <div className="content-resize">
      <button className="content-resize-increase" onClick={increaseFontSize}>
        A+
      </button>{" "}
      <button className="content-resize-increase" onClick={decreaseFontSize}>
        A-
      </button>
    </div>
  )
}

export default ContentResize
