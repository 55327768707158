import React, { useRef, useEffect } from "react"
import { Link, graphql } from "gatsby"

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ReadingProgress from "../components/readingProgress"
import ContentResize from "../components/contentResize"
import ContentSidebar from "../components/contentSidebar"
import ReadingTime from "../components/readingTime"
import ImageRenderer from "../components/imageRenderer"
import UpdatedAt from "../components/updatedAt"
import Breadcrumb from "../components/breadcrumb"

const cheerio = require("cheerio")

const PostTemplate = ({ data }) => {
  const post = data.posts
  const postEl = useRef()

  // shuffle posts array on each reload
  function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[a[i], a[j]] = [a[j], a[i]]
    }
    return a
  }
  let randomPosts = shuffle(data.allPosts.edges)

  // remove current post so it doesnt appear in the related posts
  randomPosts = randomPosts.filter(post => {
    return post.node.slug != post.slug
  })

  const metaDescription = post.excerpt.replace("<p>", "").replace("</p>", "")

  useEffect(() => {
    if (document.getElementById("ez-toc-container")) {
      const toc = document.getElementById("ez-toc-container")
      const elements = toc.getElementsByTagName("a")
      for (let el of elements) {
        el.setAttribute(
          "href",
          el.href.replace("https://iqaraislam2.wpengine.com", "")
        )
        el.setAttribute(
          "href",
          el.href.replace("https://images.iqaraislam.com", "")
        )
        // el.setAttribute("href", el.href.replace("/#", "#"))
      }
    }

    // if (typeof window !== "undefined") {
    //   const storyContainer = document.querySelector(".main-stories")
    //   storyContainer.style.maxWidth = "960px"
    // }
  }, [])

  let canonicalUrl = `${process.env.GATSBY_HOST_URL}/${post.slug}`

  // replace links that has iqaraislam2.wpengine.com to iqaraislam.com
  let postContent = post.meta.content
  let postContentHTML = cheerio.load(postContent)

  if (postContentHTML("a").length > 0) {
    for (let i = 0; i < postContentHTML("a").length; i++) {
      let link = postContentHTML("a")[i]
      if (link.attribs.href) {
        link.attribs.href = link.attribs.href.replace(
          "iqaraislam2.wpengine.com",
          "iqaraislam.com"
        )
        link.attribs.href = link.attribs.href.replace(
          "images.iqaraislam.com",
          "iqaraislam.com"
        )
      }
    }
  }

  postContentHTML = postContentHTML.html()

  const titleHTML = cheerio.load(post.name)
  const postTitle = titleHTML.text()

  const renderTags = tags => {
    return post.tags.map(tag => (
      <Link
        to={`/tags/${tag.slug}`}
        style={{
          background: "#c39b66",
          color: "#fff",
          padding: "4px 7px",
          marginRight: "7px",
          textDecoration: "none",
          display: "inline-block",
          marginBottom: "15px",
        }}
      >
        {tag.name}
      </Link>
    ))
  }

  return (
    <Layout>
      <SEO
        title={postTitle}
        description={metaDescription}
        type="article"
        url={`${process.env.GATSBY_HOST_URL}/${post.slug}`}
        category={post.category.name}
        datePublished={post.rawDate}
        dateModified={post.rawModified || ""}
        image={post.imageUrl}
        article={true}
        canonical={`${process.env.GATSBY_HOST_URL}/${post.slug}`}
      />

      <ReadingProgress target={postEl}></ReadingProgress>

      <div className="content-grid" style={{ maxWidth: 960, margin: "0 auto" }}>
        <div className="share-buttons-container">
          <div className="share-buttons">
            <FacebookShareButton
              url={canonicalUrl}
              quote={post.excerpt.replace("<p>", "").replace("</p>", "")}
              hashtag="IqaraIslam"
            >
              <FacebookIcon size={40} round />
            </FacebookShareButton>

            <TwitterShareButton
              url={canonicalUrl}
              title={post.name}
              via="IqaraIslam"
              hashtags={["IqaraIslam"]}
            >
              <TwitterIcon size={40} round />
            </TwitterShareButton>

            <WhatsappShareButton
              url={canonicalUrl}
              title={post.name}
              separator=" - "
            >
              <WhatsappIcon size={40} round />
            </WhatsappShareButton>

            <TelegramShareButton url={canonicalUrl} title={post.name}>
              <TelegramIcon size={40} round />
            </TelegramShareButton>
          </div>
        </div>

        <div className="content content-custom" ref={postEl}>
          <Breadcrumb postData={post} />

          <h1
            dangerouslySetInnerHTML={{ __html: post.name }}
            itemProp="itemReviewed"
            itemScope
            itemType="http://schema.org/Thing"
            itemProp="name"
          ></h1>

          <small
            dangerouslySetInnerHTML={{
              __html: post.excerpt.replace("<p>", "").replace("</p>", ""),
            }}
          ></small>

          <span className="content-category">
            A Redação <b>•</b> Tempo de leitura:{" "}
            <ReadingTime content={post.meta.content} />{" "}
            {post.date && (
              <>
                <b>•</b> Publicado em: {post.date}{" "}
              </>
            )}
          </span>

          <span className="content-category">{renderTags(post.tags)}</span>

          <div className="share-buttons-normal">
            <FacebookShareButton
              url={canonicalUrl}
              quote={post.excerpt.replace("<p>", "").replace("</p>", "")}
              hashtag="IqaraIslam"
            >
              <FacebookIcon size={40} round />
            </FacebookShareButton>

            <TwitterShareButton
              url={canonicalUrl}
              title={post.name}
              via="IqaraIslam"
              hashtags={["IqaraIslam"]}
            >
              <TwitterIcon size={40} round />
            </TwitterShareButton>

            <WhatsappShareButton
              url={canonicalUrl}
              title={post.name}
              separator=" - "
            >
              <WhatsappIcon size={40} round />
            </WhatsappShareButton>

            <TelegramShareButton url={canonicalUrl} title={post.name}>
              <TelegramIcon size={40} round />
            </TelegramShareButton>
          </div>

          <ImageRenderer
            imageData={post.imageUrl}
            className="content-featured-img"
          />
          {/* {renderCaption(post.featured_media)} */}

          <ContentResize />

          <div
            className="content-read"
            dangerouslySetInnerHTML={{ __html: postContentHTML }}
          />

          <div
            className="share-buttons-normal share-buttons-mobile-only"
            style={{ marginBottom: "15px" }}
          >
            <FacebookShareButton
              url={canonicalUrl}
              quote={post.excerpt.replace("<p>", "").replace("</p>", "")}
              hashtag="IqaraIslam"
            >
              <FacebookIcon size={40} round />
            </FacebookShareButton>

            <TwitterShareButton
              url={canonicalUrl}
              title={post.name}
              via="IqaraIslam"
              hashtags={["IqaraIslam"]}
            >
              <TwitterIcon size={40} round />
            </TwitterShareButton>

            <WhatsappShareButton
              url={canonicalUrl}
              title={post.name}
              separator=" - "
            >
              <WhatsappIcon size={40} round />
            </WhatsappShareButton>

            <TelegramShareButton url={canonicalUrl} title={post.name}>
              <TelegramIcon size={40} round />
            </TelegramShareButton>
          </div>

          <div className="about">
            <h3>Sobre a Redação</h3>
            <p>
              A Equipe de Redação do Iqara Islam é multidisciplinar e composta
              por especialistas na Religião Islâmica, profissionais da área de
              Marketing, Ilustração/Design, História, Administração, Tradutores
              Especializados (Árabe e Inglês). Acesse nosso{" "}
              <Link to="/quem-somos">Quem Somos</Link>.
            </p>
          </div>
        </div>

        <ContentSidebar data={data} />
      </div>

      <div className="content-grid" style={{ maxWidth: 960, margin: "0 auto" }}>
        <div />
        <div className="related-content">
          <h3>POSTAGENS RELACIONADAS</h3>

          <div className="posts">
            {randomPosts.slice(0, 3).map(node => (
              <Link to={node.node.slug} className="post" key={node.node.slug}>
                <ImageRenderer imageData={node.node.imageUrl} />
                <h2 dangerouslySetInnerHTML={{ __html: node.node.name }}></h2>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PostTemplate

export const query = graphql`
  query($id: String!, $category: String!) {
    posts(id: { eq: $id }) {
      name
      rawDate: createdAt
      postDate: createdAt(formatString: "DD/MM/YYYY")
      date: date(formatString: "DD/MM/YYYY")
      rawModified: updatedAt
      modified: updatedAt(formatString: "DD/MM/YYYY h:mm")
      excerpt
      imageUrl
      meta {
        content
      }
      slug
      category {
        name
        slug
      }
      tags {
        name
        slug
      }
    }

    allPosts(filter: { category: { slug: { eq: $category } } }) {
      edges {
        node {
          name
          slug
          imageUrl
          date: date(formatString: "D MMMM, YYYY", locale: "pt-BR")
          category {
            name
            slug
          }
        }
      }
    }

    monthPopular {
      posts {
        name
        slug
      }
    }
  }
`
