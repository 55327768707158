import React from "react"
import { Link } from "gatsby"

const Breadcrumb = ({ postData, isAMP = false }) => {
  const category = postData.category

  if (isAMP) {
    return (
      <div className="content-breadcrumb">
        <Link to="/">Página Inicial</Link> »{" "}
        <Link to={`/categorias/${category.slug}`}>{category.name}</Link>
      </div>
    )
  }

  return (
    <div className="content-breadcrumb">
      <Link to="/">Página Inicial</Link> »{" "}
      <Link to={`/categorias/${category.slug}`}>{category.name}</Link> »{" "}
      <span dangerouslySetInnerHTML={{ __html: postData.name }}></span>
    </div>
  )
}

export default Breadcrumb
