import React from "react"

const ReadingTime = ({ content }) => {
  const wordsPerMinute = 200
  let result

  let textLength = content.split(" ").length // Split by words
  if (textLength > 0) {
    let value = Math.ceil(textLength / wordsPerMinute)
    result = `~${value}`
  }

  return <>{result} minutos</>
}

export default ReadingTime
